import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Typography } from "@material-ui/core";
import { BLUE_LIGHT, REGULAR, WHITE } from "../helpers/constants";
import Marquee from "react-fast-marquee";

export default function RotationBar() {
	const styles = useStyles();

	return (
		<Marquee gradient={false} loop={0} speed={50} className={styles.container}>
			<Typography className={styles.textBar}>
				desde 2005, Protegendo você, sua família e seu patrimônio
			</Typography>
		</Marquee>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			color: WHITE,
			width: "100%",
			height: 30,
			justifyContent: "start",
			alignItems: "center",
			backgroundColor: BLUE_LIGHT,
		},
		textBar: {
			width: 650,
			whiteSpace: "nowrap",
			fontSize: 13,
			fontFamily: REGULAR,
			textTransform: "uppercase",
		},
	})
);
