import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import { BLUE_DARK, WHITE } from "../helpers/constants";
import { RiHeartPulseLine } from "react-icons/ri";
import { RiHomeHeartLine } from "react-icons/ri";
import { GiDutchBike } from "react-icons/gi";
import { FaUserInjured } from "react-icons/fa";
import { IoAirplaneOutline } from "react-icons/io5";
import { AiOutlineCar } from "react-icons/ai";
import { RiMotorbikeLine } from "react-icons/ri";

import ButtonRedirect from "./ButtonRedirect";

export default function ButtonIcons() {
	const styles = useStyles();
	const iconSize = 50 + 2 * ((window.innerWidth - 320) / 680);

	return (
		<div className={styles.container}>
			<ButtonRedirect
				label={"Porto Seguro"}
				tittle={"Vida"}
				link={
					"https://wwws.portoseguro.com.br/vendaonline/vidamaissimples/home.ns?cod=dc18acc8f6de4c0d98b27be8093835cf&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA"
				}
			>
				<RiHeartPulseLine size={iconSize} className={styles.iconStyle} />
			</ButtonRedirect>
			<ButtonRedirect
				label={"Porto Seguro"}
				tittle={"Equipamentos Portáteis"}
				link={"https://wwws.portoseguro.com.br/vendaonline/equipamentosportateis/home.ns"}
			>
				<GiDutchBike size={iconSize} className={styles.iconStyle} />
			</ButtonRedirect>

			<ButtonRedirect
				label={"Porto Seguro"}
				tittle={"Automotivo"}
				link={"https://wwws.portoseguro.com.br/vendaonline/automovel/home.ns"}
			>
				<AiOutlineCar size={iconSize} className={styles.iconStyle} />
			</ButtonRedirect>
			<ButtonRedirect
				label={"Porto Seguro"}
				tittle={"Moto"}
				link={
					"https://wwws.portoseguro.com.br/vendaonline/moto/home.ns?cod=f5e08417c02140fba00539cc194ed27b&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA#vo-lgpd-frm-step-identify"
				}
			>
				<RiMotorbikeLine size={iconSize} className={styles.iconStyle} />
			</ButtonRedirect>

			<ButtonRedirect
				label={"Porto Seguro"}
				tittle={"Residencial"}
				link={
					"https://wwws.portoseguro.com.br/vendaonline/residencia/home.ns?cod=0bec823a0d69474e961937b2fdb80a51&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA#vo-lgpd-frm-step-identify"
				}
			>
				<RiHomeHeartLine size={iconSize} className={styles.iconStyle} />
			</ButtonRedirect>
			<ButtonRedirect
				label={"Porto Seguro"}
				labelTwo={"Sulamérica"}
				tittle={"Seguro Viagem"}
				numberButtons={2}
				link={
					"https://wwws.portoseguro.com.br/vendaonline/viagem/home.ns?cod=3495e648ccc44279b2eeb494e37aa8ee&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA"
				}
				linkTwo={
					"https://portal.sulamericaseguros.com.br/seguroviagem.htm?ref=eyJkYWRvc1Byb2R1Y2FvIjp7IkFBIjoiMTY4NjgiLCJBViI6IjAiLCJFQSI6IjUzOTE0IiwiRVYiOiIyNTQ0MTcyIiwidW9wRW1pc3NhbyI6IjEzMiIsInVvcE5lZ29jaW8iOiI0ODcyIn0sImNvcnJldG9yTm9tZSI6IlNPVVpBIFBJTEFSRVMgQURNIEUgQ09SUiBERSBTRUdTIExUREEiLCJpZENvcnJldG9yIjoiOTc2MzMiLCJwZXJjZW50dWFsQ29ycmV0YWdlbSI6W3sicGVyY2VudHVhbENvcnJldGFnZW0iOiIyNS4wMCJ9LHsicGVyY2VudHVhbEFnZW5jaWFtZW50byI6IjAuMDAifSx7InBlcmNlbnR1YWxQcmVzdGFjYW9TZXJ2aWNvIjoiMC4wMCJ9LHsiaW5kZXhPcGNhbyI6IjQifV0sIm5vbWVQcm9tb3RvciI6IiIsImltZ0NvcnJldG9yIjoiaHR0cHM6Ly9jb3JyZXRvcjIuc3VsYW1lcmljYXNlZ3Vyb3MuY29tLmJyL2RhdGEvZmlsZXMvQjAvQzIvOTYvNEMvOTdDRkM2MTA0RDc3M0ZDNjlCNDYxNkE4L0xPR08lMjBub3ZhLi5wbmcifQ=="
				}
			>
				<IoAirplaneOutline size={iconSize} className={styles.iconStyle} />
			</ButtonRedirect>
			<ButtonRedirect
				label={"Porto Seguro"}
				labelTwo={"Seguro Estagiários PASI"}
				labelThree={"Capemisa Premiável"}
				tittle={"Acidentes Pessoais"}
				numberButtons={3}
				link={
					"https://www.portoseguro.com.br/seguro-de-vida/acidentes-pessoais-individual-prazo-curto"
				}
				linkTwo={
					"https://seguros.portalpasi.com.br/login?Q=LK4CqQiH9Ndx2NwJhLS0/9G3P6XKfyibuSwTx93AQFYYlVdos3U/ZntekPSTR9s91QyP+it9VJP3PiIYkaCbjkErKkUoEUu+t+BBVCN/cmFezScPOgOKVNRp/+3jJCTe"
				}
				linkThree={"https://bpc.capemisa.com.br/pages/Login.aspx"}
			>
				<FaUserInjured size={iconSize} className={styles.iconStyle} />
			</ButtonRedirect>
		</div>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "center",
			alignItems: "start",
			backgroundColor: BLUE_DARK,
		},
		buttonRedirectContainer: {
			display: "flex",
			justifyContent: "start",
			alignItems: "start",
		},
		iconStyle: {
			color: WHITE,
		},
	})
);
