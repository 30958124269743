import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Typography, Button } from '@material-ui/core';
import { BLUE, BLUE_DARK, BLUE_LIGHT, REGULAR, WHITE } from '../helpers/constants';
import PropTypes from 'prop-types';

ButtonRedirect.propTypes = {
	link: PropTypes.string.isRequired,
	linkTwo: PropTypes.string,
	linkThree: PropTypes.string,
	children: PropTypes.any.isRequired,
	tittle: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	labelTwo: PropTypes.string,
	labelThree: PropTypes.string,
	numberButtons: PropTypes.number,
};

export default function ButtonRedirect(props) {
	let haveTwoButtons = false;
	let haveThreeButtons = false;
	if (props.numberButtons === 3) {
		haveThreeButtons = true;
		haveTwoButtons = true;
	}
	if (props.numberButtons === 2) {
		haveTwoButtons = true;
		haveThreeButtons = false;
	}
	const styles = useStyles();
	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	return (
		<div className={styles.container}>
			{props.children}
			<div className={styles.tittleContainer}>
				<Typography className={styles.seguroText}>{props.tittle}</Typography>
			</div>
			<div className={styles.paddingButton}>
				<Button
					className={styles.linkButton}
					onClick={() => openInNewTab(`${props.link}`)}
					variant="contained"
					color="primary"
				>
					{props.label}
				</Button>
			</div>
			{haveTwoButtons && (
				<div className={styles.paddingButton}>
					<Button
						className={styles.linkButton}
						onClick={() => openInNewTab(`${props.linkTwo}`)}
						variant="contained"
						color="primary"
					>
						{props.labelTwo}
					</Button>
				</div>
			)}
			{haveThreeButtons && (
				<div className={styles.paddingButton}>
					<Button
						className={styles.linkButton}
						onClick={() => openInNewTab(`${props.linkThree}`)}
						variant="contained"
						color="primary"
					>
						{props.labelThree}
					</Button>
				</div>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
			alignItems: 'center',
			backgroundColor: BLUE_DARK,
			paddingBottom: 24,
			width: 100,
			[theme.breakpoints.down('sm')]: {
				width: 100,
			},
			[theme.breakpoints.up('md')]: {
				width: 130,
			},
		},
		iconStyle: {
			color: WHITE,
		},
		paddingButton: {
			paddingBottom: 5,
			paddingTop: 5,
		},
		tittleContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			height: 50,
		},
		seguroText: {
			color: WHITE,
			fontWeight: 'bold',
			textAlign: 'center',
			width: 120,
			fontFamily: REGULAR,
			[theme.breakpoints.down('sm')]: {
				fontSize: 14,
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 16,
			},
			paddingTop: '6%',
			paddingBottom: '5%',
		},
		linkButton: {
			fontSize: 12,
			fontFamily: REGULAR,
			backgroundColor: BLUE,
			color: WHITE,
			paddingTop: 7,
			paddingButton: 0,
			//height: '80%',
			height: 60,
			width: 90,
			[theme.breakpoints.down('sm')]: {
				fontSize: 11,
				height: 60,
				width: 70,
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 12,
				height: 60,
				width: 90,
			},

			textTransform: 'capitalize',
			'&:hover': {
				background: BLUE_LIGHT,
			},
		},
	})
);
