import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Typography, Button, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { BLUE, BLUE_DARK, BLUE_LIGHT, REGULAR, WHITE } from '../helpers/constants';
import PropTypes from 'prop-types';

ButtonModal.propTypes = {
	modalText: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	tittle: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	labelTwo: PropTypes.string,
	labelThree: PropTypes.string,
	numberButtons: PropTypes.number,
};

export default function ButtonModal(props) {
	const styles = useStyles();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className={styles.container}>
			{props.children}
			<div className={styles.tittleContainer}>
				<Typography className={styles.seguroText}>{props.tittle}</Typography>
			</div>
			<div className={styles.paddingButton}>
				<Button
					className={styles.linkButton}
					onClick={handleOpen}
					variant="contained"
					color="primary"
				>
					{props.label}
				</Button>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={styles.modal}
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={open}>
						<div className={styles.paper}>
							<h2 id="transition-modal-title">{props.tittle}</h2>
							<p id="transition-modal-description">{props.modalText}</p>
						</div>
					</Fade>
				</Modal>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
			alignItems: 'center',
			backgroundColor: BLUE_DARK,
			paddingBottom: 24,
			width: 100,
			[theme.breakpoints.down('sm')]: {
				width: 100,
			},
			[theme.breakpoints.up('md')]: {
				width: 130,
			},
		},
		iconStyle: {
			color: WHITE,
		},
		paddingButton: {
			paddingBottom: 5,
			paddingTop: 5,
		},
		tittleContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			height: 50,
		},
		seguroText: {
			color: WHITE,
			fontWeight: 'bold',
			textAlign: 'center',
			width: 90,
			fontFamily: REGULAR,
			[theme.breakpoints.down('sm')]: {
				fontSize: 12,
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 14,
			},
			paddingTop: '6%',
			paddingBottom: '5%',
		},
		linkButton: {
			fontSize: 12,
			backgroundColor: BLUE,
			color: WHITE,
			fontFamily: REGULAR,
			paddingTop: 7,
			paddingButton: 0,
			//height: '80%',
			height: 60,
			width: 90,
			[theme.breakpoints.down('sm')]: {
				fontSize: 11,
				height: 60,
				width: 70,
			},
			[theme.breakpoints.up('md')]: {
				fontSize: 12,
				height: 60,
				width: 90,
			},

			textTransform: 'capitalize',
			'&:hover': {
				background: BLUE_LIGHT,
			},
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 12,
			color: BLUE_DARK,
		},
	})
);
